import React from 'react'
import { CardElement } from 'react-stripe-elements'
import styled from 'styled-components'


const PaymentMethodsWrapper = styled.div`
  margin: 0 0 20px;
  border-bottom: 2px solid #e8e8fb;
  
  .StripeElement {
    flex: 1;
    padding: 0 15px;
    background: transparent;
    border-style: none;
    font-weight: 400;
    color: #31325f;
    outline: none;
    cursor: text;
  }
  
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  > li {
    display: none;
    
    &.visible {
      display: inline-block;
      margin: 0 20px 0 0;
      list-style: none;
    }
  }
  
  > input {
    
    &:checked + label {
      color: #4B4B7D;
      
      &::before {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }
  
  >  label {
    display: flex;
    flex: 1;
    cursor: pointer;
    position: relative;
    flex-direction: row;
    height: 42px;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    color: #8898aa;
    font-weight: 400;  
    font-size: 15px;
    
    > span {
      min-width: 125px;
      padding: 0 15px;
      text-align: right;
    }
    
    &:not(:last-child) {
      border-bottom: 1px solid #f0f5fa;
    }
    
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      bottom: -2px;
      left: 0;
      border-bottom: 2px solid #4B4B7D;
      opacity: 0;
      transform: scaleX(0);
      transition: all 0.25s ease-in-out;
    }
    
    &:hover {
      color: #4B4B7D;
      cursor: pointer;
      
      &::before {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }
  
  > input + label {
    position: relative;
    padding: 5px 0;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
  }
  
  > .payment-info {
    display: none;
    
    &:not(.card) {
      margin-bottom: 15px;
    }
    
    &.ideal {
      margin-bottom: 0;
    }
    
    &.visible {
      display: block;
    }
    
    &.card {
      &.visible {
        text-align: center;
      }
    }
    
    > p {
      &.notice {
        font-size: 14px;
        color: #8898aa;
        text-align: left;
      }
    }
  }
  
  &.visible {
      display: block;
  }
  
  > .element-errors {
    display: inline-flex;
    height: 20px;
    margin: 15px auto 0;
    padding-left: 20px;
    color: #e25950;
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    background: url(/images/error.svg) center left no-repeat;
    background-size: 15px 15px;
    
    &.visible {
      opacity: 1;
      transform: none;
    }
  }
  
  > input {
    &::-webkit-input-placeholder {
      color: #aab7c4;
    }
    
    &::-moz-placeholder {
      color: #aab7c4;
    }
    
    &:-ms-input-placeholder {
      color: #aab7c4;
    }
    
    &::-webkit-input-placeholder {
      color: #cfd7e0;
    }
    &::-moz-placeholder {
      color: #cfd7e0;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #cfd7e0;
    }
    
    &:-webkit-autofill,
      select:-webkit-autofill {
      -webkit-text-fill-color: #666ee8;
      transition: background-color 100000000s;
      -webkit-animation: 1ms void-animation-out 1s;
    }
  }
  
`


class CardSection extends React.Component {
  render() {
    return (
      <PaymentMethodsWrapper>
        <label>
          <span>Card</span>
          <CardElement style={{base: {fontSize: '15px'}}} />
        </label>
      </PaymentMethodsWrapper>
    )
  }
}

export default CardSection;