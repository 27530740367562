import React from 'react'
import { injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements'

import AddressSection from './AddressSection'
import CardSection from './CardSection'
import styled from "styled-components";

const FormWrapper = styled.div`
  width: 420px;
  margin: 0 auto;
  padding-top: 20px;
  
  @media only screen and (max-width : 480px) {
    width: 80%;
  }
`

const PaymentRequestButtonWrapper = styled.div`
  height: 32px;
`

const Form = styled.form`
  margin: 30px -30px 0;
  padding: 20px 30px 20px;
  border-radius: 4px;
  border: 1px solid #e8e8fb;
  
  @media only screen and (max-width : 480px) {
    padding: 20px 5px;
  }
`

const SuccessWrapper = styled.form`
  padding: 40% 0;
  text-align: center;
  
  @media only screen and (max-width : 480px) {
    padding: 20px 5px;
  }
`

const CheckoutButton = styled.button`
  display: block;
  background: #4B4B7D;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  width: 100%;
  height: 40px;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease;
  
  &:focus {
    background: #555abf;
  }
  
  &:hover {
    color: #4B4B7D;
    background: #fff;
  }
  
  &:active {
    background: #4B4B7D;
  }
  
  &:disabled,
  button[disabled]{
    cursor: wait;
    border: 1px solid #ccc;
    background-color: #d8d8d8;
    color: #888;
    box-shadow: none;
  }
`

const PlanLabel = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: 15px;
  color: #8898aa;
`

const paymentRequestButtonStyle = {
  paymentRequestButton: {
    theme: 'dark',
    height: '32px',
    maxHeight: '32px',
  },
}

const PlanDetails = props => {
  const { plan } = props

  return (
   <PlanLabel>
     { plan.label }
   </PlanLabel>
  )
}

class CheckoutForm extends React.Component {
  constructor(props) {
    super()

    if(props.stripe) {
      const paymentRequest = props.stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: props.plan['label'],
          amount: props.plan['minAmount'],
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      paymentRequest.on('token', ({ complete, token, ...data }) => {
        console.log('Received Stripe token: ', token)
        console.log('Received customer information: ', data)
        complete('success')
      })

      paymentRequest.canMakePayment().then((result) => {
        this.setState({ canMakePayment: !!result }, paymentRequest)
      })
    }

    this.state = {
      canMakePayment: false,
      paymentRequest: null,
      paymentPending: false,
      paymentSuccessful: false,
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    this.setState({ paymentPending: true })

    const formData = {
      name: event.target.name['value'],
      address_line1: event.target.address_line1['value'],
      address_city: event.target.address_city['value'],
      address_state: event.target.address_state['value'],
      address_zip: event.target.address_zip['value'],
      address_country: event.target.address_country['value'],
      email: event.target.email['value'],
    }

    let {token, error} = await this.props.stripe.createToken(formData)
    console.log('error', error)

    let body = {
      token: token && token.id,
      email: formData.email,
      plan: this.props.plan,
    }

    let response = await fetch(this.props.subscribeUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    })

    if (response.ok) {
      if (this.props.onSuccess) {
        this.props.onSuccess()
      }
      this.setState({ paymentSuccessful: true })
    }
  }

  render() {
    const { plan } = this.props
    const { paymentPending, paymentSuccessful } = this.state

    const paymentRequestButton = this.state.canMakePayment ? (
      <PaymentRequestButtonElement
        paymentRequest={this.state.paymentRequest}
        className="PaymentRequestButton"
        style={paymentRequestButtonStyle}
      />
    ) : null

    if (paymentSuccessful) {
      return (
        <SuccessWrapper>
          <p>Payment received. Thank you, we will reach out to you with next steps.</p>
        </SuccessWrapper>
      )
    }

    return (
      <FormWrapper>
        <PaymentRequestButtonWrapper>
          {paymentRequestButton}
        </PaymentRequestButtonWrapper>
        <Form onSubmit={this.handleSubmit}>
          <AddressSection showAltInstruction={this.state.canMakePayment}/>
          <CardSection />
          <PlanDetails plan={plan}/>
          { paymentPending ? (
            <CheckoutButton disabled>Processing...</CheckoutButton>
          ) : (
            <CheckoutButton>Confirm order - ${plan.minAmount / 100}</CheckoutButton>
          )}
        </Form>
      </FormWrapper>
    )
  }
}

export default injectStripe(CheckoutForm)