import React from 'react'
import styled from 'styled-components'


const AddressSectionContent = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  
  @media only screen and (max-width : 480px) {
    > h5 {
      padding-left: 10px;
    }
  }
`

const Fieldset = styled.fieldset`
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
              0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 4px;
  border: none;
  font-size: 0;
  
  > label {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 42px;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    color: #8898aa;
    font-weight: 400;  
    font-size: 15px;
    
    &:not(:last-child) {
      border-bottom: 1px solid #f0f5fa;
    }
    
    &.state {
      display: inline-flex;
      width: 75%;
      
      > span {
        padding-right: 10px;
      }
    }
    
    &.zip {
      display: inline-flex;
      width: 25%;
      padding-right: 60px;
    }
    
    > span {
      min-width: 125px;
      padding: 0 15px;
      text-align: right;
    }
  }
  
  &:not(.with-state) label.state {
    display: none;
  }
  
  &:not(.with-state) label.zip {
    width: 100%;
  }
  
  > .redirect label span {
    width: 100%;
    text-align: center;
  }
  
  @media only screen and (max-width : 480px) {
    > label {
      > span { 
        padding: 0 15px 0 0;
        min-width: 80px;
        font-size: 13px;
      }
    }
  }
`

const Instruction = styled.p`
  display: inline-table;
  margin-top: -32px;
  padding: 0 5px;
  text-align: center;
  background: #fff;
  font-size: 15px;  
  
  @media only screen and (max-width : 480px) {
    font-size: 12px;
  }
`

const Field = styled.input`
  flex: 1;
  padding: 0 15px;
  background: transparent;
  border-style: none;
  font-weight: 400;
  color: #31325f;
  outline: none;
  cursor: text;
  
  &::-webkit-input-placeholder {
    color: #aab7c4;
  }
  
  &::-moz-placeholder {
    color: #aab7c4;
  }
  
  &:-ms-input-placeholder {
    color: #aab7c4;
  }
  
  &::-webkit-input-placeholder {
    color: #cfd7e0;
  }
  &::-moz-placeholder {
    color: #cfd7e0;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #cfd7e0;
  }
  
  &:-webkit-autofill,
    select:-webkit-autofill {
    -webkit-text-fill-color: #666ee8;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out 1s;
  }
`

const Select = styled.select`
  flex: 1;
  border-style: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  
  &:focus {
    color: #666ee8;
  }
  
  > span {
    min-width: 125px;
    padding: 0 15px;
    text-align: right;
  }
  
  &::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    right: 20px;
    top: 50%;
    margin-top: -2px;
    pointer-events: none;
  }
`

const Country = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const CountrySelect = styled(Select)`
  margin: 0 -15px 0 -30px;
  padding: 0 15px 0 30px;
`


const AddressSection = props => {
  return (
    <AddressSectionContent>
      { props.showAltInstruction ? (
          <Instruction>Or complete your payment details below</Instruction>
        ) : (
          <Instruction>Complete your payment details below</Instruction>
        )
      }
      <h5>Billing Information</h5>
      <Fieldset className="with-state">
        <label>
          <span>Name</span>
          <Field name="name" placeholder="Jenny Rosen" required />
        </label>
        <label>
          <span>Email</span>
          <Field name="email" type="email"
                 placeholder="jenny@acmeco.com" required />
        </label>
        <label>
          <span>Address</span>
          <Field name="address_line1"
                 placeholder="185 Berry Street Suite 550" />
        </label>
        <label>
          <span>City</span>
          <Field name="address_city" placeholder="San Francisco" />
        </label>
        <label className="state">
          <span>State</span>
          <Field name="address_state" placeholder="CA" />
        </label>
        <label className="zip">
          <span>ZIP</span>
          <Field name="address_zip" placeholder="94107" />
        </label>
        <label>
          <span>Country</span>
          <Country id="country" className="field US">
            <CountrySelect name="address_country" defaultValue='US'>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="BE">Belgium</option>
              <option value="BR">Brazil</option>
              <option value="CA">Canada</option>
              <option value="CN">China</option>
              <option value="DK">Denmark</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
              <option value="HK">Hong Kong</option>
              <option value="IE">Ireland</option>
              <option value="IT">Italy</option>
              <option value="JP">Japan</option>
              <option value="LU">Luxembourg</option>
              <option value="MX">Mexico</option>
              <option value="NL">Netherlands</option>
              <option value="NZ">New Zealand</option>
              <option value="NO">Norway</option>
              <option value="PT">Portugal</option>
              <option value="SG">Singapore</option>
              <option value="ES">Spain</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
            </CountrySelect>
          </Country>
        </label>
      </Fieldset>
  </AddressSectionContent>
  )
}


export default AddressSection