import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { StripeProvider } from 'react-stripe-elements'
import { Helmet } from 'react-helmet'

import SEO from '../components/seo'
import StickyHeader from '../components/header-main'
import RequestForm from '../components/Forms/RequestForm'
import Footer from '../components/footer'

import { LightSecondaryButton, WhiteSecondaryButton } from '../components/UI/Buttons'
import Signup from '../components/Signup/Signup'

import ExitIntent from '../components/ExitIntent'
import { Interview, OnlineTest, Analysis, FillForms, FriendsOnline, AtWork } from '../svgs'
import information from '../images/information.png'

import '../styles/index.css'



const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
`

const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
`

const Hero = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background: #69d49f;
`

const Title = styled.h1`
  flex: 1;
  padding: 20px 0 0 20px;
  text-align: center;
  line-height: 50px;
  color: #000000;
  font-weight: 400;
  font-size: 30px;
  
  @media only screen and (max-width : 480px) {
    font-size: 24px;
    line-height: 32px;
    max-width: 340px;
    margin: 0 auto 25px; 
  }  
`

const Subtitle = styled.p`
  flex: 1;
  width: 70%;
  margin: 0 auto;
  padding: 0px 0 0 0;
  line-height: 32px;
  text-align: justify;
  color: #000000;
  font-weight: 200;
  font-size: 22px;
  
  @media only screen and (max-width : 480px) {
    font-size: 18px;
    line-height: 26px;
  }  
`

const Information = styled.div`
  flex: 0;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  width: 100%;
  background-image: url(${information});
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  width: 270px;
  
  @media only screen and (max-width : 480px) {
    width: 240px;
  } 
`

const Section = styled.section`
  background: #f8f8f8;
  min-height: 200px;
  border-bottom: 1px solid #ddd;
`

const ProblemSection = styled(Section)`
  background: white;
  min-height: 250px;
  border-bottom: 1px solid #ddd;
`

const HowItWorksSection = styled(Section)`
  min-height: 250px;
  border-bottom: 1px solid #ddd;
`

const PricingSection = styled(Section)`
  min-height: 810px;
  border-bottom: 1px solid #ddd;
  background: white;
  
  @media only screen and (max-width : 480px) {
    min-height: 600px
  }
`

const RequestDemoSection = styled(Section)`
  background: white;
  min-height: 210px;
  border-bottom: 1px solid #ddd;
`

const Problem = styled(Section)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100px;
  background: white;
  border-bottom: 1px dashed #ddd;
  padding: 20px 0;
  
  &:last-of-type {
    border-bottom: 0 none;
  }
  
  @media only screen and (max-width : 480px) {
    padding-bottom: 20px;
  }  
`

const ProblemContent = styled.div`
  width: 40%;
  margin: 0 40px;
  align-self: center;
  
  @media only screen and (max-width : 480px) {
    margin-top: 40px;
    width: 100%;
  }  
`

const ProblemBody = styled.p`
  color: #444;
  font-size: 17px;
`

const ProblemImage = styled.div`
  display: inline-block;
  height: 200px;
  align-self: center;
 
  @media only screen and (max-width : 480px) {
    flex: 1 0 auto;
  }  
  
`

const LightCTA = styled(Section)`
  background: white;
  min-height: 0;
`

const DarkCTA = styled(LightCTA)`
  background: #69d49f;
  min-height: 0;
`

const Solutions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`

const Solution = styled(Section)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 30%;
  align-self: top;
  min-height: 100px;
  border-bottom: 0 none;
  padding: 30px 0;
  margin: 1%;
  flex: 1 0 auto;

  @media only screen and (max-width : 640px) {
    width: 100%;
    padding-bottom: 0;
    border-bottom: 1px dashed #ddd;
    
    &:last-of-type {
      border-bottom: 0 none;
    }
  } 
`

const SolutionContent = styled.div`
  margin: 30px 0;
  padding: 0 30px;
  
  @media only screen and (max-width : 480px) {
    margin-top: 40px;
    width: 100%;
  } 
`

const SolutionTitle = styled.h4`
  font-size: 16px;
  color: #2D3F4F;
  margin-bottom: 5px;
`

const SolutionBody = styled.p`
  color: #666;
  font-size: 15px;
  line-height: 21px;
`

const SolutionIntro = styled.p`
  font-size: 15px;
  width: 80%;
  margin: 0 auto;
`

const SolutionImage = styled.div`
  display: block;
  height: 150px;
  
  @media only screen and (max-width : 480px) {
    flex: 1 0 auto;
  }  
`

const SectionTitle = styled.h2`
  padding: 30px 0 0 0;
  text-align: center;
  font-weight: 200;
  color: #2D3F4F;
`

const CTAButtons = styled.div`
  display: flex;
  margin: 0 auto;
  font-size: 16px;
  
  @media only screen and (max-width : 480px) {
    flex-direction: column;
    align-items: center;
  } 
`

const HeroBox = styled.div`
  margin: 220px 0;
  background: white;
  
  @media only screen and (max-width : 480px) {
    margin-top: 90px;
  }
`

const HeroImage = styled.div`
  width: 48%;
  margin: -230px auto 0;
  
  @media only screen and (max-width : 480px) {
    margin-top: -88px;
  }
`

class StripeProviderWrapper extends React.Component {

  state = {
    stripe: null
  }

  componentDidMount() {
    const stripeKey = process.env.GATSBY_STRIPE_PUBLIC_KEY

    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripeKey) })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(stripeKey) })
      })
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        { this.props.children }
      </StripeProvider>
    )
  }
}

class MainPage extends React.Component {

  render() {
    return (
    <StaticQuery
      query={graphql`
        query MainPageQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <StripeProviderWrapper>
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: "description",
                  content: "SalaryAlly - Salary negotiation platform.",
                },
                {
                  name: "keywords",
                  content:
                    "salary, negotiation, compensation, pay gap",
                },
              ]}
              link={[]}
              defer={false}
            />
            <SEO title={data.site.siteMetadata.title}/>
            <Hero>
              <StickyHeader siteTitle={data.site.siteMetadata.title} transparent={true} />
              <HeroContainer>
                <HeroBox>
                  <HeroImage>
                    <Interview />
                  </HeroImage>
                  <Title>Hi there, <br />Welcome to your next salary negotiation. Are you prepared?</Title>
                  <Subtitle>
                    SalaryAlly helps job candidates understand their market value, and  prepare to negotiate their best salary, whether for a new job or an internal raise.
                    <br /><br />
                    We built this for the striving every(wo)man who is trying to move ahead and create a better future. We built it for men, women, and companies that want to help close the pay and equity opportunity gap.
                  </Subtitle>
                  <CTAButtons>
                    <LightSecondaryButton style={{width: '234px'}}>
                      <Link to={'/#pricing'} style={{width: '230px', padding: '10px'}}>
                        Get your report
                      </Link>
                    </LightSecondaryButton>
                  </CTAButtons>
                </HeroBox>
              </HeroContainer>
            </Hero>
            <ProblemSection id='learn-more'>
              <Container>
                <SectionTitle>
                  We looked out at the current job market and saw three problems
                </SectionTitle>
                <Problem>
                  <ProblemImage>
                    <Information />
                  </ProblemImage>
                  <ProblemContent>
                    <ProblemBody>
                      Recruiters and companies have more informational advantages than you do on salary and pay.
                    </ProblemBody>
                  </ProblemContent>
                </Problem>
                <Problem style={{ 'flexDirection': 'row-reverse' }}>
                  <ProblemImage>
                    <OnlineTest/>
                  </ProblemImage>
                  <ProblemContent>
                    <ProblemBody>
                      Candidates told us the salary data points on the existing online databases were too broad to help them negotiate.
                    </ProblemBody>
                  </ProblemContent>
                </Problem>
                <Problem>
                  <ProblemImage>
                    <Analysis/>
                  </ProblemImage>
                  <ProblemContent>
                    <ProblemBody>
                      The gender equity gap persists. Women will soon be the majority of the US college-educated workforce, yet on average earn $26,000 a year less than equally credentialed male peers.
                    </ProblemBody>
                  </ProblemContent>
                </Problem>
              </Container>
            </ProblemSection>
            <LightCTA>
              <CTAButtons>
                <LightSecondaryButton style={{width: '234px'}}>
                  <Link to={'/#pricing'} style={{width: '230px', padding: '10px'}}>
                    Get your report
                  </Link>
                </LightSecondaryButton>
              </CTAButtons>
            </LightCTA>
            <HowItWorksSection>
              <Container>
                <SectionTitle>
                  How it Works
                </SectionTitle>
                <SolutionIntro>
                  If you're currently interviewing for a new job, plan on renegotiating your salary at your current company, or beginning to plan for a career change - we’ll build you a custom salary appraisal report on your market value or comparables for a specific role you are seeking.
                </SolutionIntro>
                <Solutions>
                  <Solution>
                    <SolutionImage>
                      <FillForms/>
                    </SolutionImage>
                    <SolutionContent>
                      <SolutionTitle>
                        Give us your background
                      </SolutionTitle>
                      <SolutionBody>
                        You provide us background information on yourself and the role you are negotiating for.
                      </SolutionBody>
                    </SolutionContent>
                  </Solution>
                  <Solution>
                    <SolutionImage>
                      <FriendsOnline/>
                    </SolutionImage>
                    <SolutionContent>
                      <SolutionTitle>
                        We'll research the market
                      </SolutionTitle>
                      <SolutionBody>
                        We gather 3-5 independently vetted data points from recruiters, hiring managers, and compensation experts.
                      </SolutionBody>
                    </SolutionContent>
                  </Solution>
                  <Solution>
                    <SolutionImage>
                      <AtWork/>
                    </SolutionImage>
                    <SolutionContent>
                      <SolutionTitle>
                        You get a custom report
                      </SolutionTitle>
                      <SolutionBody>
                        We'll produce a custom report for you to help you in your promotion or new offer negotiation.
                      </SolutionBody>
                    </SolutionContent>
                  </Solution>
                </Solutions>
              </Container>
            </HowItWorksSection>
            <DarkCTA>
              <CTAButtons>
                <WhiteSecondaryButton style={{width: '234px'}}>
                  <Link to={'/#pricing'} style={{width: '230px', padding: '10px'}}>
                    Get your report
                  </Link>
                </WhiteSecondaryButton>
              </CTAButtons>
            </DarkCTA>
            <PricingSection id='pricing'>
              <Signup />
            </PricingSection>
            <RequestDemoSection id='request-demo'>
              <SectionTitle>
                Stay in touch
              </SectionTitle>
              <RequestForm formKey='newsletter' source={'stay-in-touch-footer'} />
            </RequestDemoSection>
            <Footer/>
            <ExitIntent />
          </>
        </StripeProviderWrapper>
      )}
    />)
  }
}

export default MainPage
