import React  from 'react'
import { Elements } from 'react-stripe-elements'

import InjectedCheckoutForm from '../Stripe/CheckoutForm'


const Signup = props => {

  const plan = {
      id: 'basic',
      display_name: 'Basic',
      description: `Get a SalaryAlly Report`,
      label: '',
      price: 49500,
      minAmount: 49500,
  }

  return (
    <>
      <Elements>
        <InjectedCheckoutForm plan={plan} onSuccess={props.onSuccess} subscribeUrl={process.env.GATSBY_SUBSCRIBE_URL} />
      </Elements>
    </>
  )
}

export default Signup